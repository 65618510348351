<template>
  <div class="content">
    <div>
      <a-card style="width: 100%; border-radius: 4px">
        <div class="top-title">
          <!-- <a-icon type="smile" style="font-size: 38px; color: rgb(127, 98, 173); margin-right: 20px" /> -->
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="database"
            class="svg-inline--fa fa-database fa-fw fa-lg"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            width="32px"
            height="32px"
            style="margin-right: 5px"
          >
            <path
              fill="currentColor"
              d="M448 80v48c0 44.2-100.3 80-224 80S0 172.2 0 128V80C0 35.8 100.3 0 224 0S448 35.8 448 80zM393.2 214.7c20.8-7.4 39.9-16.9 54.8-28.6V288c0 44.2-100.3 80-224 80S0 332.2 0 288V186.1c14.9 11.8 34 21.2 54.8 28.6C99.7 230.7 159.5 240 224 240s124.3-9.3 169.2-25.3zM0 346.1c14.9 11.8 34 21.2 54.8 28.6C99.7 390.7 159.5 400 224 400s124.3-9.3 169.2-25.3c20.8-7.4 39.9-16.9 54.8-28.6V432c0 44.2-100.3 80-224 80S0 476.2 0 432V346.1z"
            ></path>
          </svg>
          {{ $t('传感器模块.传感器标题') }}
        </div>
        <div class="top-middle">{{ $t('传感器模块.传感器介绍') }}</div>
        <div style="margin-top: 16px">
          <a-auto-complete
            allowClear
            v-model="form.serialNumber"
            :data-source="dataSource"
            style="width: 100%"
            :placeholder="$t('通用.输入.请输入')"
            :filter-option="filterOption"
            @focus="getfocus"
          >
              
          </a-auto-complete>
        </div>
        <div style="margin-top: 16px">
          <a-button class="searchBtn" @click="searchExport" :disabled="form.serialNumber == ''"
            >{{ $t('通用.按钮.查询') }}
          </a-button>
        </div>
      </a-card>
    </div>

    <!--上部表格-->
    <div style="margin-top: 16px" v-show="show">
      <a-card style="width: 100%; border-radius: 4px">
        <div>
          <a-row :gutter="16">
            <!--<a-col :span="7">-->
            <!--<a-input v-model="queryParam.serialNumber" :placeholder="$t('导出.序列号')" size="large" />-->
            <!--</a-col>-->
            <a-col :md="6" :sm="24">
              <a-form-item prop="startIntroducedAt">
                <a-date-picker
                  :placeholder="$t('算法运行.开始时间')"
                  style="width: 100%"
                  v-model="queryParam.startIntroducedAt"
                  :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  allow-clear
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item prop="endIntroducedAt">
                <a-date-picker
                  :placeholder="$t('算法运行.结束时间')"
                  style="width: 100%"
                  v-model="queryParam.endIntroducedAt"
                  :show-time="{ defaultValue: moment('23:59:59', 'HH:mm:ss') }"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  allow-clear
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-button class="searchBtn" @click="search">{{ $t('算法运行.搜索') }}</a-button>
              <a-button class="searchBtn searchBtn1" @click="reset">{{ $t('学习模块.清空') }}</a-button>
            </a-col>
          </a-row>
        </div>
        <div style="margin-top: 16px">
          <a-table :loading="loading" rowKey="id" :columns="columns" :data-source="list" :pagination="false">
            <span slot="createTime" slot-scope="text, record">
              {{ parseTime(record.createTime) }}
            </span>
            <span slot="operation" slot-scope="text, record">
              <a @click="handleExport(record)" style="color: rgb(127, 98, 173)">
                {{ $t('通用.按钮.下载') }}
                <a-icon type="download" />
              </a>
            </span>

            <template slot="recordedAt" slot-scope="text, record">
              <a-tooltip>
                <template slot="title">
                  {{ record.introducedAt.substring(5, 7) }}/{{ record.introducedAt.substring(8, 10) }}/{{
                    record.introducedAt.substring(0, 4)
                  }}
                  {{ record.introducedAt.substring(11, 16) }}
                  <span v-if="record.introducedAt.substring(11, 13) <= 12"> AM </span>
                  <span v-if="record.introducedAt.substring(11, 13) > 12"> PM </span>
                </template>
                {{ record.introducedAt.substring(5, 7) }}/{{ record.introducedAt.substring(8, 10) }}/{{
                  record.introducedAt.substring(2, 4)
                }}
              </a-tooltip>
            </template>

            <template slot="firstIntroducedAt" slot-scope="text, record">
              <a-tooltip>
                <template slot="title">
                  {{ record.firstIntroducedAt.substring(5, 7) }}/{{ record.firstIntroducedAt.substring(8, 10) }}/{{
                    record.firstIntroducedAt.substring(0, 4)
                  }}
                  {{ record.firstIntroducedAt.substring(11, 16) }}
                  <span v-if="record.firstIntroducedAt.substring(11, 13) <= 12"> AM </span>
                  <span v-if="record.firstIntroducedAt.substring(11, 13) > 12"> PM </span>
                </template>
                {{ record.firstIntroducedAt.substring(5, 7) }}/{{ record.firstIntroducedAt.substring(8, 10) }}/{{
                  record.firstIntroducedAt.substring(2, 4)
                }}
              </a-tooltip>
            </template>

            <template slot="lastIntroducedAt" slot-scope="text, record">
              <a-tooltip>
                <template slot="title">
                  {{ record.lastIntroducedAt.substring(5, 7) }}/{{ record.lastIntroducedAt.substring(8, 10) }}/{{
                    record.lastIntroducedAt.substring(0, 4)
                  }}
                  {{ record.lastIntroducedAt.substring(11, 16) }}
                  <span v-if="record.lastIntroducedAt.substring(11, 13) <= 12"> AM </span>
                  <span v-if="record.lastIntroducedAt.substring(11, 13) > 12"> PM </span>
                </template>
                {{ record.lastIntroducedAt.substring(5, 7) }}/{{ record.lastIntroducedAt.substring(8, 10) }}/{{
                  record.lastIntroducedAt.substring(2, 4)
                }}
              </a-tooltip>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>

    <!--上部表格-->

    <div style="margin-top: 16px">
      <a-card style="width: 100%; border-radius: 4px">
        <div>
          <Table @handleRowClick="handlerowclick" />
        </div>
      </a-card>
    </div>

    <!-- 弹出框 -->
    <Modal ref="ModalRefs" />
  </div>
</template>

<script>
import { AutoComplete } from 'ant-design-vue'
import Table from './modules/table.vue'
import Modal from './modules/Modal.vue'
import moment from 'moment'

import { queryExport, SearchpageExport, Export } from '@/api/export/export'
import { pageStudies } from '@/api/studies/studies'
import { pageMemory, listMemory, delMemory, listSixMemory } from '@/api/memory/memory'

export default {
  data() {
    return {
      list: [],
      memoryList: [],
      form: {
        serialNumber: '',
        startIntroducedAt: undefined,
        endIntroducedAt: undefined,
        pageNum: 1,
        pageSize: 10,
      },
      // 查询参数
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        serialNumber: undefined,
        startIntroducedAt: undefined,
        endIntroducedAt: undefined,
      },
      columns: [
        {
          title: this.$t('导出.序列号'),
          dataIndex: 'serialNumber',
          key: 'serialNumber',
        },
        {
          title: this.$t('导出.记录时间'),
          dataIndex: 'introducedAt',
          key: 'introducedAt',
          scopedSlots: { customRender: 'recordedAt' },
        },
        {
          title: this.$t('导出.fw版本号'),
          dataIndex: 'fwVersion',
          key: 'fwVersion',
        },
        {
          title: this.$t('导出.读取次数'),
          dataIndex: 'readings',
          key: 'readings',
        },
        {
          title: this.$t('导出.首次记录时间'),
          dataIndex: 'firstIntroducedAt',
          key: 'firstIntroducedAt',
          scopedSlots: { customRender: 'firstIntroducedAt' },
        },
        {
          title: this.$t('导出.最后一次记录时间'),
          dataIndex: 'lastIntroducedAt',
          key: 'lastIntroducedAt',
          scopedSlots: { customRender: 'lastIntroducedAt' },
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
      show: false,
      open1: false,
      dataSource: [],
    }
  },
  components: {
    Table,
    Modal,
    'a-auto-complete': AutoComplete,
  },
  created() {
    this.getList()
    // this.sixMemoryList()
  },
  methods: {
    moment,
    /** 查询列表 */
    getList() {
      this.loading = true
      pageStudies(this.queryParam).then((response) => {
        this.list = response.data.records
        // this.total = response.data.total
        this.loading = false
      })
    },
    searchExport() {
      this.show = true
      this.loading = true
      queryExport(this.form).then((res) => {
        console.log('export', res)
        this.list = res.data.records
        this.loading = false
      })
    },
    /** 导出按钮操作 */
    handleExport(record) {
      let serialNumber = record.serialNumber
      var that = this
      that.queryParam.serialNumber = record.serialNumber
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk() {
          that.download('deviceReading/deviceReading/export', that.queryParam, `${serialNumber}.csv`)
        },
        onCancel() {},
      })
    },

    search() {
      queryExport(this.queryParam).then((res) => {
        console.log('export', res)
        this.list = res.data.records
        this.loading = false
      })
    },
    reset() {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        serialNumber: undefined,
        startIntroducedAt: undefined,
        endIntroducedAt: undefined,
      }

      queryExport(this.queryParam).then((res) => {
        console.log('export', res)
        this.list = res.data.records
        this.loading = false
      })
    },

    openModal() {
      this.$refs.ModalRefs.openModal()
    },
    handlerowclick() {
      console.log('点击行')
    },

    //获得焦点
    getfocus() {
      this.sixMemoryList()
    },
    //失去焦点
    losefocus() {
      this.memoryList = []
    },

    //查询联词搜索
    sixMemoryList() {
      let param = {}
      param.meunType = 'EXPORT'
      listSixMemory(param).then((response) => {
        // this.memoryList = response.data
        this.dataSource = []
        response.data.forEach(item =>{
              this.dataSource.push(item.searchWord)
        })
      })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
    },
  },
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .top-title {
    align-items: center;
    display: flex;
    font-size: 1.6rem;
    font-weight: 600;
    color: #212529;
  }

  .top-middle {
    margin-top: 8px;
    font-size: 16px;
    color: #212529;
    font-family: Poppins, sans-serif;
  }

  .btn {
    color: rgb(127, 98, 173);
    //    background-color: rgb(127, 98, 173);
    font-weight: 500;
    font-size: 16px;
    margin-top: 16px;
    //    border-radius: .25rem;
    width: 126px;
    height: 38px;
    //    color: #fff;
    border: none !important;
  }

  .searchBtn {
    color: rgb(127, 98, 173);
    background-color: rgb(127, 98, 173);
    font-weight: 500;
    font-size: 16px;
    margin-top: 16px;
    border-radius: 0.25rem;
    // width: 126px;
    height: 38px;
    color: #fff;
    margin-top: 0px !important;
    margin-top: 0px !important;
  }

  .searchBtn1 {
    margin-top: 0px !important;
  }
}
</style>