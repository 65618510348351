import request from '@/utils/request'

// 查询页面统计数据
export function queryExport(query) {
  return request({
    url: '/cgmDevice/cgmDevice/page',
    method: 'get',
    params: query
  })
}


// 搜索分页
export function SearchpageExport(query) {
  return request({
    url: '/cgmDevice/cgmDevice/page',
    method: 'get',
    params: query
  })
}

// 导出
export function Export(data) {
  return request({
    url: '/cgmReading/cgmReading/export',
    method: 'post',
    params: data
  })
}

export function queryPcbaExport(query) {
  return request({
    url: '/pcba/pcba/page',
    method: 'get',
    params: query
  })
}

